
.NoInformation{
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    img{
        width: 254px;
        height: 180px;
    }
    span{
        margin-top: 50px;
        color: #878994;
        font-size: 14px;
    }
}
table tr td{
    border-bottom: 1px solid #EEEEEE;
}
.StoreMarketingThree{
    height: 100%;
    .MarketingThreeHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .deleBtn{
            width: 90px;
            height: 30px;
            line-height: 0;
            color: #EB6547;
            background: #ffffff;
            border-color: #EB6547;
        }
    }
    .toBtn{
        display: flex;
        justify-content: center;
        margin-top: 80px;
        .btn1{
            width: 100px;
            height: 30px;
            line-height: 0;
        }
        .btn1:nth-child(1){
            background: #ffffff;
            border-color: #1122D8;
            color: #1122D8;
        }
        .btn1:nth-child(2){
            background: #1122D8;
            border-color: #1122D8;
            color: #ffffff;
        }
    }
    .MarketingThreeTable{
        .table{
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            border: 1px solid #EEEEEE;
            .tableHeader{
                display: flex;
                text-align: center;
                .input-checkbox{
                    position: absolute;
                    left: 6px;
                    top:20px;
                    width: 12px;
                    height: 12px;
                    /*border-color: red!important;*/
                }
                .headerItem{
                    width: 20%;
                    background: #F2F3FA;
                    height: 60px;
                    line-height: 60px;
                    padding-left: 10px;
                }
            }
            .tableBody{
                position: relative;
                .input-checkOne{
                    position: absolute;
                    left: 6px;
                    top: 20px;
                    width: 12px;
                    height: 12px;
                    border: 1px solid red;
                }
                .itemBox{
                    display: flex;
                    .bodyItem:nth-child(2){
                        width: 30%;
                    }
                    .bodyItem{
                        text-align: center;
                        width: 20%;
                        height: 60px;
                        line-height: 60px;
                        padding-left: 10px;
                        border-bottom: 1px solid #EEEEEE;
                    }
                }

            }
        }
        .tableBox{
            margin-top: 10px;
            border-spacing:0;
            width: 100%;
            border: 1px solid #EEEEEE;

            tr{
                height: 60px;
                th{
                    font-size: 14px;
                }
            }
            .newContent{
                span{
                    color: #343441;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
        .noData{
            color: #878994;
            font-size: 16px;
            position: absolute;
            left: 48%;
            bottom: 10px;
        }
    }
}
